$(document).ready(function () {

    $.validator.addMethod("accept", function (value, element, param) {
        // Check if the element is optional and value is empty
        if (this.optional(element)) return true;

        // Get the file extension
        var fileExtension = value.split('.').pop().toLowerCase();

        // Convert the param to an array, and remove any spaces
        param = (typeof param === "string" ? param.replace(/\s/g, "") : "doc|docx|pdf|txt").split('|');
        // Check if the file extension is in the allowed list
        return param.includes(fileExtension);
    }, $.validator.format("Please enter a value with a valid file extension (doc, docx, pdf, txt)."));

    $(' .form-processing').css('display', 'none');

    var isDesktop = true;
    if ($(window).width() >= 991) {
        isDesktop = true;
    } else {
        isDesktop = false;
    }
    if ($("#enquiryForm").length > 0) {
        debugger
        if (isDesktop) {
            $(".mobile_html #enquiryForm").remove()
        } else {
            $(".desktop_html #enquiryForm").remove()

        }
        fileInputReset('#enquiryForm', '#docFile');
        enquiryFormValidate("#enquiryForm");
        resetFormMessage('#enquiryForm');
        inputTelFunction('#phone', '#countryCode', 'us', '#enquiryForm');
    }
    if ($("#enquiryFormP").length > 0) {
        if (isDesktop) {
            $(".mobile_html #enquiryFormP").remove()
        } else {
            $(".desktop_html #enquiryFormP").remove()
        }
        fileInputReset('#enquiryFormP', '#docFile');
        enquiryFormValidate("#enquiryFormP");
        resetFormMessage('#enquiryFormP');
        inputTelFunction('#phone', '#countryCode', 'us', '#enquiryFormP');
    }
    if ($("#letsTalkForm").length > 0) {
        if (isDesktop) {
            $(".mobile_html #letsTalkForm").remove()
        } else {
            $(".desktop_html #letsTalkForm").remove()
        }
        letsTalkFormValidate();
        resetFormMessage('#letsTalkForm');
    }

    if ($("#getInTouchForm").length > 0) {

        getInTouchFormValidate();
        resetFormMessage('#getInTouchForm');
    }

    if ($("#otherLandingPageForm").length > 0) {
        if (isDesktop) {
            $(".mobile_html #otherLandingPageFormPopup_mob").remove()
        } else {
            $(".desktop_html #otherLandingPageFormPopup").remove()
        }
        otherLandingPageFormValidate();
        resetFormMessage('#otherLandingPageForm');
        inputTelFunction('#phone', '#countryCode');
    }

    if ($("#contactForm").length > 0) {
        if (isDesktop) {
            $(".mobile_html #contactForm").remove()
        } else {
            $(".desktop_html #contactForm").remove()
        }
        contactFormValidate();
        resetFormMessage('#contactForm');
        inputTelFunction('#contactPhone', '#contactCountryCode');
    }


    if ($("#getin_touch_popup").length > 0) {
        if (isDesktop) {
            $(".mobile_html #getin_touch_popup").remove()
        } else {
            $(".desktop_html #getin_touch_popup").remove()
        }
        contactFormValidate();
        resetFormMessage('#getin_touch_popup');
        inputTelFunction('#touchPhone', '#contactCountryCodeGetInTouch');
        console.log(touchPhone)
    }

    if ($("#careerForm").length > 0) {
        if (isDesktop) {
            $(".careerForm #contactForm").remove()
            $(".mobile_html #jobFormAndContentDialog").remove()
        } else {
            $(".careerForm #contactForm").remove()
            $(".desktop_html #jobFormAndContentDialog").remove()
        }
        fileInputReset('#careerForm', '#careerDoc')
        careerFormValidate();
        resetFormMessage('#careerForm');
        inputTelFunction('#careerPhone', '#careerCountryCode', 'in');
    }

    showCareerForm()
    trimSpaces(":text")
    trimSpaces("textarea")
    setPreviousUrl(isDesktop)
    //scrollDetect()
    check()
    menuScrollControl()
    scrollDetectServicePage()
    scrollDetectSliderStartStop()
})

function setFormSubmittedSuccess() {
    if (typeof (Storage) !== "undefined") {
        sessionStorage.setItem('thankYou', 'yes');
    }
}

// function check() {
//     if($('.c-fixed-left-menu').length > 0){
//         var scrollMenuWrap = $('.c-fixed-left-menu')
//         var scrollTop = scrollMenuWrap.scrollTop()
//         var height = scrollMenuWrap.height()
//         var offset = scrollMenuWrap.offset()

//         let menuHeight = $('.c-fixed-left-menu').height()
//         let windowHeight = $( window ).height()
//         let difference = 150
//         let difference1 = windowHeight - menuHeight - 80

//         if (100 < offset.top) {
//             $('.sift-menu-top').hide();
//             $('.sift-menu-down').show();
//         }if(offset.top < difference1){
//             $('.sift-menu-down').hide();
//             $('.sift-menu-top').show();
//         } else if (difference1 <  offset.top && offset.top < 70) {

//             $('.sift-menu-down').show();
//             $('.sift-menu-top').show();
//         }
//     }
// }

// function menuScrollControl(){
//     $('.sift-menu-top').on( "click", function() {
//         let offset1 = $('.c-fixed-left-menu').offset().top
//         console.log('offset new 1', offset1)
//         let offsetData = {top: offset1+68, left: 0}
//         $('.c-fixed-left-menu').offset(offsetData) 
//         check()
//     })
//     $('.sift-menu-down').on( "click", function() {
//         let offset = $('.c-fixed-left-menu').offset().top
//         console.log('offset new', offset)
//         let offsetData = {top: offset-67, left: 0}
//         $('.c-fixed-left-menu').offset(offsetData) 

//         check()
//     })
// }

// function scrollDetect () {
//     alert('hello1')
//     $(window).scroll(function() {
//         var $nav = $(".services_main");
//         $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
//     });
// }

function scrollDetectSliderStartStop() {
    // alert('hello')
    if ($('.detect-auto-scroll .carousel').length > 0) {
        $('.detect-auto-scroll .carousel').each(function (index) {
            let currLink = $(this);
            let refElement = $(currLink);
            refElement.carousel('pause')
        })
        $(window).scroll(function () {
            var scrollPos = $(document).scrollTop();
            $('.detect-auto-scroll .carousel').each(function (index) {
                // $(this).carousel('pause')
                var detectWrapper = $(this).closest('.detect-auto-scroll')
                var currLink = $(this);
                var refElement = $(currLink);
                if ((detectWrapper.position().top - 150) <= scrollPos && detectWrapper.position().top + detectWrapper.height() + 200 > scrollPos) {
                    $('.carousel').removeClass("active1"); //added to remove active class from all a elements
                    // console.log('add active')
                    refElement.addClass("active1");
                    $('.carousel').carousel('pause')
                    refElement.carousel('cycle')
                }
                else {
                    // console.log('remove active')
                    refElement.removeClass("active1");
                    refElement.carousel('pause')
                }
            });

        });
    }
    if ($('.detect-auto-scroll .owl-carousel').length > 0) {
        $('.detect-auto-scroll .owl-carousel').each(function (index) {
            let currLink = $(this);
            let refElement = $(currLink);
            refElement.trigger('stop.owl.autoplay');
        })
        $(window).scroll(function () {
            var scrollPos = $(document).scrollTop();
            $('.detect-auto-scroll .owl-carousel').each(function (index) {
                // $(this).carousel('pause')
                var currLink = $(this);
                var refElement = $(currLink);
                // console.log('refElement.position().top', refElement.parent('.tech_we').position().top, 'scrollPos', scrollPos, 'refElement.height()', refElement.height())
                // console.log('first', ((refElement.parent('.tech_we').position().top - 150) <= scrollPos))
                // console.log('second', ((refElement.parent('.tech_we').position().top + refElement.parent('.tech_we').height() + 200) > scrollPos))
                if (((refElement.parent('.detect-auto-scroll').position().top - 150) <= scrollPos) && ((refElement.parent('.detect-auto-scroll').position().top + refElement.parent('.detect-auto-scroll').height() + 200) > scrollPos)) {
                    $('.owl-carousel').removeClass("active1"); //added to remove active class from all a elements
                    // console.log('add active owl-carousel')
                    refElement.addClass("active1");
                    $('.owl-carousel').trigger('stop.owl.autoplay');
                    refElement.trigger('play.owl.autoplay');
                }
                else {
                    // console.log('remove active owl-carousel')
                    refElement.removeClass("active1");
                    refElement.trigger('stop.owl.autoplay');
                }
            });

        });
    }
}

function scrollDetectServicePage() {
    $(window).scroll(function () {
        if ($('.serv_bx').length > 0) {
            var scrollPos = $(document).scrollTop();
            $('.serv_bx').each(function () {
                var currLink = $(this);
                var refElement = $(currLink);
                if ((refElement.position().top - 100) <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                    $('.c-nav').removeClass("active"); //added to remove active class from all a elements
                    $('#' + currLink.data('id')).addClass("active");
                }
                else {
                    $('#' + currLink.data('id')).removeClass("active");
                }
            });
        }
    });
}

function setPreviousUrl(isDesktop) {

    const url_string = window.location.href
    const url = new URL(url_string);
    const previousUrl = url.searchParams.get("previousUrl");
    if (previousUrl != null) {
        if (isDesktop) {
            $(".mobile_html #previousPage").remove()
        } else {
            $(".desktop_html #previousPage").remove()
        }
        $('#previousPage').attr('href', previousUrl)
    }
}

function trimSpaces(element) {
    $(element).each(function (index) {
        $(this).focusout(function () {
            var text = $(this).val();
            text = $.trim(text);
            $(this).val(text);
        });
    });
}

function showCareerForm() {
    $('.showCareerForm').click(function () {
        const formName = '#' + $(this).data('jobname')
        $('#careerJobProfile').val(formName)
        $('.job-content-wrapper').hide()
        $(formName).show()
        $('#jobFormAndContentDialog').modal('show');
    })
}

function fileInputReset(formType, fileElement) {
    const fileInput = formType + ' ' + fileElement
    const clearField = formType + ' ' + ".clearFile"

    $('.clearFile').click(function () {
        $(clearField).hide();
        $(fileInput).val('')
        $(formType + ' .fileName').html('Attach Your Document')
    })
    $(fileInput).change(function () {
        const fileDoc = $(fileInput)[0].files;
        if (fileDoc !== 0) {
            $(clearField).show();
            $(formType + ' .fileName').html(fileDoc[0].name);
        } else {
            $(clearField).hide();
            $(formType + ' .fileName').html('Attach Your Document')
        }
    })
}

//custom validation function to validate file size
$.validator.addMethod('filesize', function (value, element, param) {
    // param = size (en bytes) 
    // element = element to validate (<input>)
    // value = value of the element (file name)
    return this.optional(element) || (element.files[0].size <= param)
});
$.validator.addMethod("customemail",
    function (value, element) {
        return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w{2,12}([-.]\w+)*$/.test(value);
        // return /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);

    },
    "Sorry, I've enabled very strict email validation"
);


function thankyouPageRedirect() {
    setFormSubmittedSuccess()
    var hostname = window.location.origin
    const currentUrl = window.location.href
    window.location.href = hostname + "/thankyou?previousUrl=" + currentUrl;
}
function thankyouPageRedirectcareer() {
    setFormSubmittedSuccess()
    var hostname = window.location.origin
    const currentUrl = window.location.href
    window.location.href = hostname + "/thankyou-for-visiting-us?previousUrl=" + currentUrl;
}

function thankyouPageRedirectOtherPages() {
    setFormSubmittedSuccess()
    var hostname = window.location.origin
    const currentUrl = window.location.href
    window.location.href = hostname + "/thankyou-au?previousUrl=" + currentUrl;
}
function thankyouPageRedirectAuPages() {
    setFormSubmittedSuccess()
    var hostname = window.location.origin
    const currentUrl = window.location.href
    window.location.href = hostname + "/thankyou-australia?previousUrl=" + currentUrl;
}

function resetFormMessage(id) {
    $(id).on('keyup change paste', 'input, select, textarea', function () {
        $(id + ' .success').hide();
        $(id + ' .success').html('');
        $(id + ' .form-error').html('');
        $(id + ' .form-error').hide();
    })
}

async function enquiryForm(wrapper) {

    // Get some values from elements on the page:
    var dataForm = $(wrapper).serializeArray().reduce(function (obj, item) {
        obj[item.name] = item.value;
        return obj;
    }, {});
    try {

        const fileDoc = $(wrapper + ' #docFile')[0].files[0];
        // console.log('fileDoc', fileDoc)
        var formData = new FormData();
        let message = dataForm.message
        let currentLocation = window.location.href || ''
        message = message + " current page url: " + currentLocation
        formData.append("name", dataForm.name);
        formData.append("email", dataForm.email);
        formData.append("phone", dataForm.phone);
        formData.append("whenToStart", 3);
        formData.append("countryCode", dataForm.countryCode);
        formData.append("company", dataForm.company);
        formData.append("message", message);
        if (fileDoc != undefined) {
            formData.append("file", fileDoc, fileDoc.name);
        }
        // console.log(wrapper, dataForm)
        const url = "https://sven.dianapps.com/api/submit-enquiry-form";
        // const url = "http://localhost:3000/api/submit-enquiry-form";

        var settings = {
            "url": url,
            "method": "POST",
            "timeout": 0,
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": formData
        };
        $(wrapper + ' .form-processing').css('display', 'flex');
        $.ajax(settings).done(function (dataResp) {
            $(wrapper + ' .form-processing').css('display', 'none');
            const result = JSON.parse(dataResp)
            $(wrapper + ' .form-error').hide();
            $(wrapper).trigger("reset");
            $(wrapper + ' .clearFile').hide();

            let redirection = $(wrapper).data('redirection');
            console.log('redirection', redirection);
            if (redirection == 'self') {
                if ($('#project_enquery')) {
                    $('#project_enquery').modal('hide');
                } else {
                    $('#project_enquery_mob').modal('hide');
                }
                setTimeout(() => {
                    $('#project_enquery_thankyou').modal('hide');
                }, 5000);
                $('#project_enquery_thankyou').modal('show');
                return true;
            } else {
                thankyouPageRedirect()
            }

        }).fail(function (errorResp) {
            $(wrapper + ' .form-processing').css('display', 'none');
            const error = JSON.parse(errorResp.responseText)
            $(wrapper + ' .success').hide();
            $(wrapper + ' .form-error').html(error.message)
            $(wrapper + ' .form-error').show();
        });

    }
    catch (err) {
        console.log('err', err)
    }
}

function enquiryFormValidate(wrapper) {

    $(wrapper).validate({
        // Specify validation rules
        rules: {
            name: {
                required: true
            },
            phone: {
                //required: true,
                digits: true,
                maxlength: 12,
                minlength: 7
            },
            message: {
                required: true,
                minlength: 20,
                maxlength: 3500
            },
            // whenToStart: {
            //     required: true,
            // },
            email: {
                required: true,
                customemail: true
            },
            docFile: {
                filesize: 2097152,
                accept: "doc|docx|pdf|txt"
            }
        },
        // Specify validation error messages
        messages: {
            name: "Please enter your full name",
            phone: {
                // required: "Phone number is required",
                digits: 'Must be a number'
            },
            message: {
                required: 'Message is required',
                minlength: 'Min 20 character required',
                maxlength: 'Max 3500 character allowed'
            },
            // whenToStart: {
            //     required: "When To Start is required",
            // },
            email: {
                required: "Email is required",
                customemail: "Please enter a valid email address"
            },
            docFile: {
                filesize: "File size must be less then 2MB",
                accept: 'Please enter a value with a valid file extension (doc, docx, pdf, txt).'
            }
        },
        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid
        submitHandler: function (form) {
            enquiryForm(wrapper);
        }
    });
}

function getInTouchFormValidate() {

    $("#getInTouchForm").validate({
        // Specify validation rules
        rules: {
            touchName: {
                required: true
            },
            touchMessage: {
                required: true,
                minlength: 20,
                maxlength: 3500
            },
            touchEmail: {
                required: true,
                customemail: true,
                // customemail: function(element) {
                //     console.log('element', element.value)
                //     //var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                //     var mailformat =/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                //     if(element.value.match(mailformat)){
                //         console.log('valid')
                //         return true
                //     } else {
                //         console.log('invalid')
                //         return false
                //     }
                // },
            },
            touchPhone: {
                required: true,
                digits: true,
                maxlength: 12,
                minlength: 7
            },
        },
        // Specify validation error messages
        messages: {
            touchName: {
                required: "Name is required"
            },
            touchMessage: {
                required: "Message is required",
                minlength: 'Min 20 character required',
                maxlength: 'Max 3500 character allowed'
            },
            touchEmail: {
                required: "Email is required",
                customemail: "Please enter a valid email address"
            },
            touchPhone: {
                required: "Phone number is required",
                customemail: "Please enter a valid phone number"
            }
        },
        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid
        submitHandler: function (form) {
            touchForm();
        }
    });
}

function touchForm() {
    try {
        console.log("touch form");
        // Get some values from elements on the page:
        const dataForm = $('#getInTouchForm').serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});
        var formData = new FormData();
        let message = dataForm.touchMessage
        let currentLocation = window.location.href || ''
        message = message + " current page url: " + currentLocation
        formData.append("name", dataForm.touchName);
        formData.append("email", dataForm.touchEmail);
        formData.append("countryCode", dataForm.contactCountryCode);
        formData.append("phone", dataForm.touchPhone);
        formData.append("message", message);
        // formData.append("countryCode", dataForm.touchCountryCode);

        const url = "https://sven.dianapps.com/api/submit-touch-form";
        // const url = "http://localhost:3000/api/submit-talk-form";

        var settings = {
            "url": url,
            "method": "POST",
            "timeout": 0,
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": formData
        };
        $('#getInTouchForm .form-processing').css('display', 'flex');
        $.ajax(settings).done(function (dataResp) {
            var hostname = window.location.origin
            $('#getInTouchForm .form-processing').css('display', 'none');
            const result = JSON.parse(dataResp)
            console.log("result==============>", result)
            $('#getInTouchForm .form-error').hide();
            // $('#letsTalkForm .success').html(result.message);
            // $('#letsTalkForm .success').show();
            $('#getInTouchForm').trigger("reset");

            let redirection = $('#getInTouchForm').data('redirection');
            if (redirection == 'self') {
                if ($('#getin_touch_popup')) {
                    $('.js-modal-close').trigger('click');
                }
                setTimeout(() => {
                    $('#project_enquery_thankyou').modal('hide');
                }, 5000);
                $('#project_enquery_thankyou').modal('show');
                return true;
            } else {
                thankyouPageRedirect()
            }

            thankyouPageRedirect()
        }).fail(function (errorResp) {
            $('#getInTouchForm .form-processing').css('display', 'none');
            const error = JSON.parse(errorResp.responseText)
            console.log("error", error);
            $('#getInTouchForm .success').hide();
            $('#getInTouchForm .form-error').html(error.message)
            $('#getInTouchForm .form-error').show();
        });

    } catch (err) {
        console.log('err', err)
    }
}


if ($("#getInTouchFormDev").length > 0) {
    getInTouchFormDevValidate();
    resetFormMessage('#getInTouchFormDev');
    inputTelFunction('#touchPhoneDev', '#countryCodeDev');
}

function getInTouchFormDevValidate() {

    $("#getInTouchFormDev").validate({
        // Specify validation rules
        rules: {
            touchName: {
                required: true
            },
            touchMessage: {
                required: true,
                minlength: 20,
                maxlength: 3500
            },
            touchEmail: {
                required: true,
                customemail: true,

            },
            touchPhoneDev: {
                required: true,
                digits: true,
                maxlength: 12,
                minlength: 7
            },
        },
        // Specify validation error messages
        messages: {
            touchName: {
                required: "Name is required"
            },
            touchMessage: {
                required: "Message is required",
                minlength: 'Min 20 character required',
                maxlength: 'Max 3500 character allowed'
            },
            touchEmail: {
                required: "Email is required",
                customemail: "Please enter a valid email address"
            },
            touchPhoneDev: {
                required: "Phone number is required",
                customemail: "Please enter a valid phone number"
            }
        },
        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid
        submitHandler: function (form) {
            touchFormDev();
        }
    });
}

function touchFormDev() {
    try {
        console.log("touch form");
        // Get some values from elements on the page:
        const dataForm = $('#getInTouchFormDev').serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});
        var formData = new FormData();
        let message = dataForm.touchMessage
        let currentLocation = window.location.href || ''
        message = message + " current page url: " + currentLocation
        formData.append("name", dataForm.touchName);
        formData.append("email", dataForm.touchEmail);
        formData.append("countryCode", dataForm.contactCountryCode);
        formData.append("phone", dataForm.touchPhoneDev);
        formData.append("message", message);
        // formData.append("countryCode", dataForm.touchCountryCode);

        const url = "https://sven.dianapps.com/api/submit-touch-form";
        // const url = "http://localhost:3000/api/submit-talk-form";

        var settings = {
            "url": url,
            "method": "POST",
            "timeout": 0,
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": formData
        };
        $('#getInTouchFormDev .form-processing').css('display', 'flex');
        $.ajax(settings).done(function (dataResp) {
            var hostname = window.location.origin
            $('#getInTouchFormDev .form-processing').css('display', 'none');
            const result = JSON.parse(dataResp)
            console.log("result==============>", result)
            $('#getInTouchFormDev .form-error').hide();
            // $('#letsTalkForm .success').html(result.message);
            // $('#letsTalkForm .success').show();
            $('#getInTouchFormDev').trigger("reset");

            let redirection = $('#getInTouchFormDev').data('redirection');
            if (redirection == 'self') {
                if ($('#getin_touch_popup')) {
                    $('.js-modal-close').trigger('click');
                }
                setTimeout(() => {
                    $('#project_enquery_thankyou').modal('hide');
                }, 5000);
                $('#project_enquery_thankyou').modal('show');
                return true;
            } else {
                thankyouPageRedirect()
            }

            thankyouPageRedirect()
        }).fail(function (errorResp) {
            $('#getInTouchFormDev .form-processing').css('display', 'none');
            const error = JSON.parse(errorResp.responseText)
            console.log("error", error);
            $('#getInTouchFormDev .success').hide();
            $('#getInTouchFormDev .form-error').html(error.message)
            $('#getInTouchFormDev .form-error').show();
        });

    } catch (err) {
        console.log('err', err)
    }
}




function otherLandingPageFormValidate() {

    $("#otherLandingPageForm").validate({
        // Specify validation rules
        rules: {
            touchName: {
                required: true
            },
            touchMessage: {
                // required: true,
                minlength: 0,
                maxlength: 3500
            },
            phone: {
                // required: true,
                digits: true,
                maxlength: 12,
                minlength: 7

            },
            touchEmail: {
                required: true,
                customemail: true,
                // customemail: function(element) {
                //     console.log('element', element.value)
                //     //var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                //     var mailformat =/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                //     if(element.value.match(mailformat)){
                //         console.log('valid')
                //         return true
                //     } else {
                //         console.log('invalid')
                //         return false
                //     }
                // },
            }
        },
        // Specify validation error messages
        messages: {
            touchName: {
                required: "Name is required"
            },
            touchMessage: {
                //required: "Message is required",
                minlength: 'Min 20 character required',
                maxlength: 'Max 3500 character allowed'
            },
            phone: {
                // required: "Phone number is required",
                digits: 'Must be a number',
                maxlength: 'Phone number cannot exceed 12 digits',
                minlength: 'Phone number must be at least 7 digits'
            },
            touchEmail: {
                required: "Email is required",
                customemail: "Please enter a valid email address"
            }
        },
        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid
        submitHandler: function (form) {
            otherLandingPageForm();
        }
    });
}
// Restrict phone input to digits only and enforce maxlength
$('#phoneClutch').on('input', function () {
    this.value = this.value.replace(/\D/g, '').slice(0, 12);
});

function otherLandingPageForm() {
    try {

        // Get some values from elements on the page:
        const dataForm = $('#otherLandingPageForm').serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        var formData = new FormData();
        let message = dataForm.touchMessage
        let currentLocation = window.location.href || ''
        message = message + " current page url: " + currentLocation
        formData.append("name", dataForm.touchName);
        formData.append("email", dataForm.touchEmail);
        formData.append("message", message);
        formData.append("phone", dataForm.phone);
        formData.append("countryCode", dataForm.countryCode);
        formData.append("formTitle", dataForm.formTitle);


        console.log('data touch form', formData)
        const url = "https://sven.dianapps.com/api/landing-form";
        // const url = "http://localhost:3000/api/landing-form";

        var settings = {
            "url": url,
            "method": "POST",
            "timeout": 0,
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": formData
        };
        $('#otherLandingPageForm .form-processing').css('display', 'flex');
        $.ajax(settings).done(function (dataResp) {
            var hostname = window.location.origin
            $('#otherLandingPageForm .form-processing').css('display', 'none');
            const result = JSON.parse(dataResp)
            $('#otherLandingPageForm .form-error').hide();
            // $('#letsTalkForm .success').html(result.message);
            // $('#letsTalkForm .success').show();
            $('#otherLandingPageForm').trigger("reset");

            let redirection = $('#otherLandingPageForm').data('redirection');
            if (redirection == 'self') {
                if ($('#otherLandingPageFormPopup').length) {
                    $('#otherLandingPageFormPopup').modal('hide');
                } else if ($('#otherLandingPageFormPopup_mob')) {
                    $('#otherLandingPageFormPopup_mob').modal('hide');
                }
                thankyouPageRedirectOtherPages()

                // setTimeout(()=>{
                //     $('#landingpage_thankyou').modal('hide');
                // },5000);
                // $('#landingpage_thankyou').modal('show');
                return true;
            } else {
                thankyouPageRedirect()
            }

            thankyouPageRedirect()
        }).fail(function (errorResp) {
            $('#otherLandingPageForm .form-processing').css('display', 'none');
            const error = JSON.parse(errorResp.responseText)
            $('#otherLandingPageForm .success').hide();
            $('#otherLandingPageForm .form-error').html(error.message)
            $('#otherLandingPageForm .form-error').show();
        });

    } catch (err) {
        console.log('err', err)
    }
}

function letsTalkFormValidate() {

    $("#letsTalkForm").validate({
        // Specify validation rules
        rules: {
            talkName: {
                required: true
            },
            talkMessage: {
                required: true,
                minlength: 20,
                maxlength: 3500
            },
            talkEmail: {
                required: true,
                customemail: true,
                // customemail: function(element) {
                //     console.log('element', element.value)
                //     //var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                //     var mailformat =/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                //     if(element.value.match(mailformat)){
                //         console.log('valid')
                //         return true
                //     } else {
                //         console.log('invalid')
                //         return false
                //     }
                // },
            }
        },
        // Specify validation error messages
        messages: {
            talkName: {
                required: "Name is required"
            },
            talkMessage: {
                required: "Message is required",
                minlength: 'Min 20 character required',
                maxlength: 'Max 3500 character allowed'
            },
            talkEmail: {
                required: "Email is required",
                customemail: "Please enter a valid email address"
            }
        },
        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid
        submitHandler: function (form) {
            letsTalkForm();
        }
    });
}

function letsTalkForm() {
    try {

        // Get some values from elements on the page:
        const dataForm = $('#letsTalkForm').serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        var formData = new FormData();
        let message = dataForm.talkMessage
        let currentLocation = window.location.href || ''
        message = message + " current page url: " + currentLocation
        formData.append("name", dataForm.talkName);
        formData.append("email", dataForm.talkEmail);
        formData.append("message", message);

        console.log('data lets talk', formData)
        const url = "https://sven.dianapps.com/api/submit-talk-form";
        // const url = "http://localhost:3000/api/submit-talk-form";

        var settings = {
            "url": url,
            "method": "POST",
            "timeout": 0,
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": formData
        };
        $('#letsTalkForm .form-processing').css('display', 'flex');
        $.ajax(settings).done(function (dataResp) {
            var hostname = window.location.origin
            $('#letsTalkForm .form-processing').css('display', 'none');
            const result = JSON.parse(dataResp)
            $('#letsTalkForm .form-error').hide();
            // $('#letsTalkForm .success').html(result.message);
            // $('#letsTalkForm .success').show();
            $('#letsTalkForm').trigger("reset");
            thankyouPageRedirect()
        }).fail(function (errorResp) {
            $('#letsTalkForm .form-processing').css('display', 'none');
            const error = JSON.parse(errorResp.responseText)
            $('#letsTalkForm .success').hide();
            $('#letsTalkForm .form-error').html(error.message)
            $('#letsTalkForm .form-error').show();
        });

    } catch (err) {
        console.log('err', err)
    }
}

$.validator.addMethod("maxPhoneDigits", function (value, element) {
    return this.optional(element) || /^\d{1,12}$/.test(value);
}, "Phone number must not exceed 12 digits.");

$(document).ready(function () {
    // Attach an event listener to the input field
    $('.NumberValidate').on('input', function () {
        // Remove non-numeric characters
        let inputValue = $(this).val().replace(/\D/g, '');

        // Limit the input to 12 digits
        if (inputValue.length > 12) {
            inputValue = inputValue.slice(0, 12);
        }

        // Update the input field value
        $(this).val(inputValue);
    });
});


function contactFormValidate() {

    $("#contactForm").validate({
        // Specify validation rules
        rules: {
            contactName: {
                required: true,
            },
            contactPhone: {
                required: true,
                digits: true,
                maxlength: 12,
                minlength: 7
            },
            contactEmail: {
                required: true,
                customemail: true
            },
            contactMessage: {
                required: true,
                minlength: 20,
                maxlength: 3500
            }
        },
        // Specify validation error messages
        messages: {
            contactName: {
                required: "Name is required"
            },
            contactPhone: {
                // required: "Phone number required",
                digits: 'Only number required'
            },
            contactMessage: {
                required: "Message is required",
                minlength: 'Min 20 character required',
                maxlength: 'Max 3500 character allowed'
            },
            contactEmail: {
                required: "Email is required",
                customemail: "Please enter a valid email address"
            }
        },
        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid
        submitHandler: function (form) {
            contactForm();
        }
    });
}

function contactForm() {
    try {

        // Get some values from elements on the page:
        const dataForm = $('#contactForm').serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        const formData = new FormData();
        let message = dataForm.contactMessage
        let currentLocation = window.location.href || ''
        message = message + " current page url: " + currentLocation
        formData.append("name", dataForm.contactName);
        formData.append("email", dataForm.contactEmail);
        formData.append("message", message);
        formData.append("phone", dataForm.contactPhone);
        formData.append("countryCode", dataForm.contactCountryCode);

        console.log('data contact form', formData)
        const url = "https://sven.dianapps.com/api/contact-form";
        // const url = "http://localhost:3000/api/contact-form";

        var settings = {
            "url": url,
            "method": "POST",
            "timeout": 0,
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": formData
        };
        $('#contactForm .form-processing').css('display', 'flex');
        $.ajax(settings).done(function (dataResp) {
            $('#contactForm .form-processing').css('display', 'none');
            const result = JSON.parse(dataResp)
            $('#contactForm .form-error').hide();
            // $('#contactForm .success').html(result.message);
            // $('#contactForm .success').show();
            $('#contactForm').trigger("reset");
            thankyouPageRedirect()
        }).fail(function (errorResp) {
            $('#contactForm .form-processing').css('display', 'none');
            const error = JSON.parse(errorResp.responseText)
            $('#contactForm .success').hide();
            $('#contactForm .form-error').html(error.message)
            $('#contactForm .form-error').show();
        });

    }
    catch (err) {
        console.log('err', err)
    }
}

function careerFormValidate() {
    const careerForm = $("#careerForm");
    var isExperience = false;
    $('.isExperience').change(function () {
        var isExperienceValue = $(this).val();
        if (isExperienceValue == 1) {
            isExperience = true
            $('.jobExperienField').show(200)
        } else {
            isExperience = false
            $('.jobExperienField').hide(200)
        }
    })

    $("#careerForm").validate({
        // Specify validation rules
        rules: {
            careerName: {
                required: true
            },
            careerPhone: {
                required: true,
                digits: true,
                maxlength: 12,
                minlength: 7
            },
            careerEmail: {
                required: true,
                customemail: true
            },
            careerMessage: {
                required: true,
                minlength: 20,
                maxlength: 3500
            },
            careerDoc: {
                required: true,
                filesize: 2097152,
                accept: "doc|docx|pdf|txt"
            },
            careerIsJobExperience: {
                required: true
            },
            careerJobExperienceYear: {
                required: function (element) {
                    return isExperience
                },
                digits: true
            },
            careerJobExperienceMonths: {
                required: function (element) {
                    return isExperience
                },
                digits: true,
                min: 0,
                max: 12
            },
            careerPerviousSalary: {
                required: function (element) {
                    return isExperience
                }
            },
            careerExpectedSalary: {
                required: function (element) {
                    return isExperience
                }
            },
            careerCurrentCompany: {
                required: function (element) {
                    return isExperience
                }
            },
            careerNoticePeriod: {
                required: function (element) {
                    return isExperience
                }
            }
        },
        // Specify validation error messages
        messages: {
            careerName: {
                required: "Name is required"
            },
            careerPhone: {
                required: "Phone number is required",
                digits: 'Only number required'
            },
            careerEmail: {
                required: "Email is required",
                customemail: "Please enter a valid email address"
            },
            careerMessage: {
                required: "Message is required",
                minlength: 'Min 20 character required',
                maxlength: 'Max 3500 character allowed'
            },
            careerDoc: {
                required: 'Document is required',
                filesize: 'Document must be less then 2MB',
                // extension: 'Only docs,doc,pdf file fomate allowed',
                accept: 'Please enter a value with a valid file extension (doc, docx, pdf, txt).'
            },
            careerIsJobExperience: {
                required: "Please select job experienced or not",
            },
            careerJobExperienceYear: {
                required: "How many years of job experience is required",
                digits: 'Please enter how many year'
            },
            careerJobExperienceMonths: {
                required: "How many months of job experience is required",
                digits: 'Please enter how many months',
                min: 'value must be between 0 - 12 ',
                max: 'value must be between 0 - 12 '
            },
            careerPerviousSalary: {
                required: "Pervious salary is required"
            },
            careerExpectedSalary: {
                required: "Expected salary is required"
            },
            careerCurrentCompany: {
                required: "Current company name is required"
            },
            careerNoticePeriod: {
                required: "Notice period is required"
            }
        },
        errorPlacement: function (error, element) {
            if (element.attr("name") == "careerIsJobExperience") {
                error.appendTo(element.closest('.form-group'));
            } else if (element.attr("name") == "careerDoc") {
                error.insertAfter(".d-flex.align-items-center");
            } else {
                error.insertAfter(element);
            }
        },
        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid
        submitHandler: function () {
            careerFormSubmit();
        }
    });
}

function careerFormSubmit() {
    try {
        // Get some values from elements on the page:
        const dataForm = $('#careerForm').serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});


        const fileDoc = $('#careerDoc')[0].files[0];

        var formData = new FormData();
        let message = dataForm.careerMessage
        let currentLocation = window.location.href || ''
        message = message + " current page url: " + currentLocation
        formData.append("name", dataForm.careerName);
        formData.append("jobProfile", dataForm.careerJobProfile);
        formData.append("email", dataForm.careerEmail);
        formData.append("message", message);
        formData.append("phone", dataForm.careerPhone);
        formData.append("countryCode", dataForm.careerCountryCode);
        formData.append("isExp", dataForm.careerIsJobExperience);
        formData.append("expYear", dataForm.careerJobExperienceYear);
        formData.append("expMonth", dataForm.careerJobExperienceMonths);
        formData.append("perviousSalary", dataForm.careerPerviousSalary);
        formData.append("expectedSalary", dataForm.careerExpectedSalary);
        formData.append("currentCompany", dataForm.careerCurrentCompany);
        formData.append("noticePeriod", dataForm.careerNoticePeriod);
        if (fileDoc != undefined) {
            formData.append("file", fileDoc, fileDoc.name);
        }
        let spreadSheetData = {
            "name": dataForm.careerName || '',
            "email": dataForm.careerEmail || '',
            "jobProfile": dataForm.careerJobProfile || '',
            "countryCode": dataForm.careerCountryCode || '',
            "phone": dataForm.careerPhone || '',
            "isExp": dataForm.careerIsJobExperience || '',
            "expYear": dataForm.careerJobExperienceYear || '',
            "expMonth": dataForm.careerJobExperienceMonths || '',
            "previousSalary": dataForm.careerPerviousSalary || '',
            "expectedSalary": dataForm.careerExpectedSalary || '',
            "currentCompany": dataForm.careerCurrentCompany || '',
            "noticePeriod": dataForm.careerNoticePeriod || '',
            "message": message
        }
        const url = "https://sven.dianapps.com/api/submit-career-form";
        // const url = "http://localhost:3000/api/submit-career-form";

        var settings = {
            "url": url,
            "method": "POST",
            "timeout": 0,
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": formData
        };

        $('#careerForm .form-processing').css('display', 'flex');
        $.ajax(settings).done(function (dataResp) {
            $('#careerForm .form-processing').css('display', 'none');
            const result = JSON.parse(dataResp)
            $('#careerForm .form-error').hide();
            // $('#careerForm .success').html(result.message);
            // $('#careerForm .success').show();
            $('#careerForm').trigger("reset");
            $('#careerForm .clearFile').hide();


            careerSpreadSheetForm(spreadSheetData)
        }).fail(function (errorResp) {
            // thankyouPageRedirect()
            thankyouPageRedirectcareer()

            $('#careerForm .form-processing').css('display', 'none');
            const error = JSON.parse(errorResp.responseText)
            $('#careerForm .success').hide();
            $('#careerForm .form-error').html(error.message)
            $('#careerForm .form-error').show();
        });

    } catch (err) {
        console.log('err', err)
    }
}

function careerSpreadSheetForm(data) {
    $.ajax({
        url: "https://api.apispreadsheets.com/data/UZpEaCAs195WJPIJ/",
        type: "post",
        data: JSON.stringify({ "data": data }),
        success: function () {
            // thankyouPageRedirect()
            thankyouPageRedirectcareer()
        },
        error: function () {
            // thankyouPageRedirect()
            thankyouPageRedirectcareer()
            $('#careerForm .form-processing').css('display', 'none');
            const error = JSON.parse(errorResp.responseText)
            $('#careerForm .success').hide();
            $('#careerForm .form-error').html(error.message)
            $('#careerForm .form-error').show();
        }
    });
}

function inputTelFunction(id, countryCodeEle, initialCountry = 'us', wrapper = '') {
    var telInput = $(wrapper + ' ' + id);
    var errorMsg = $("#error-msg");
    var validMsg = $("#valid-msg");

    // initialise plugin
    telInput.intlTelInput({
        allowExtensions: true,
        formatOnDisplay: true,
        autoFormat: true,
        autoHideDialCode: true,
        autoPlaceholder: true,
        defaultCountry: "us",
        ipinfoToken: "yolo",
        hiddenInput: "full_phone",
        nationalMode: false,
        numberType: "MOBILE",
        //onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
        preferredCountries: ['sa', 'ae', 'qa', 'om', 'bh', 'kw', 'ma'],
        preventInvalidNumbers: true,
        separateDialCode: true,
        initialCountry: initialCountry,
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js"
    });

    var reset = function () {
        telInput.removeClass("error");
        errorMsg.addClass("hide");
        validMsg.addClass("hide");
    };

    // on blur: validate
    telInput.blur(function () {
        reset();
        if ($.trim(telInput.val())) {
            var getCode = telInput.intlTelInput('getSelectedCountryData').dialCode;
            $(wrapper + ' ' + countryCodeEle).val(getCode);
            if (telInput.intlTelInput("isValidNumber")) {
                validMsg.removeClass("hide");
            } else {
                telInput.addClass("error");
                errorMsg.removeClass("hide");
            }
        }
    });

    // on keyup / change flag: reset
    telInput.on("keyup change", reset);
}





$.validator.addMethod("validEmail", function (value, element) {
    // Use a regular expression for email validation
    return /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i.test(value);
}, "Please enter a valid email address");

$("#salesForm").validate({

    // Specify validation rules
    rules: {
        fullName: {
            required: true,
        },
        companyName: {
            required: true,
        },
        companyEmail: {
            required: true,
            validEmail: true
        }
    },
    // Specify validation error messages
    messages: {
        fullName: {
            required: "Name is required"
        },
        companyName: {
            required: "Company Name is required"
        },
        companyEmail: {
            required: "Email is required",
            companyEmail: "Please enter a valid email address"
        }
    },
    // Make sure the form is submitted to the destination defined
    // in the "action" attribute of the form when valid
    submitHandler: function (form) {
        salesForm(form);
    }
});
function salesForm(form) {
    try {

        const dataForm = $(form).serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        console.log('Serialized form data:', dataForm);

        const formData = new FormData();

        $.each(dataForm, function (key, value) {
            formData.append(key, value);
        });

        const currentLocation = window.location.href || '';

        //for debugging
        console.log('Final data sales form:', formData);
        for (const [key, value] of formData.entries()) {
            console.log(`data pritnt -${key}: ${value}`);
        }
        //end debugging

        const url = "https://sven.dianapps.com/api/salesforce/get-in-touch";
        // const url = "http://localhost:3000/api/contact-form";

        var settings = {
            "url": url,
            "method": "POST",
            "timeout": 0,
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": formData
        };
        $('#salesForm .form-processing').css('display', 'flex');
        $.ajax(settings).done(function (dataResp) {
            $('#salesForm .form-processing').css('display', 'none');
            const result = JSON.parse(dataResp)
            $('#salesForm .form-error').hide();
            // $('#contactForm .success').html(result.message);
            // $('#contactForm .success').show();
            $('#salesForm').trigger("reset");
            thankyouPageRedirect()
        }).fail(function (errorResp) {
            $('#salesForm .form-processing').css('display', 'none');
            const error = JSON.parse(errorResp.responseText)
            $('#salesForm .success').hide();
            $('#salesForm .form-error').html(error.message)
            $('#salesForm .form-error').show();
        });

    }
    catch (err) {
        console.log('err', err)
    }
}




function auLandingFormValidation() {

    $("#auLandingForm").validate({
        // Specify validation rules
        rules: {
            touchName: {
                required: true
            },
            touchMessage: {
                // required: true,
                minlength: 0,
                maxlength: 3500
            },
            phone: {
                // required: true,
                digits: true
            },
            touchEmail: {
                required: true,
                customemail: true,
                // customemail: function(element) {
                //     console.log('element', element.value)
                //     //var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                //     var mailformat =/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                //     if(element.value.match(mailformat)){
                //         console.log('valid')
                //         return true
                //     } else {
                //         console.log('invalid')
                //         return false
                //     }
                // },
            }
        },
        // Specify validation error messages
        messages: {
            touchName: {
                required: "Name is required"
            },
            touchMessage: {
                //required: "Message is required",
                minlength: 'Min 20 character required',
                maxlength: 'Max 3500 character allowed'
            },
            phone: {
                // required: "Phone number is required",
                digits: 'Must be a number'
            },
            touchEmail: {
                required: "Email is required",
                customemail: "Please enter a valid email address"
            }
        },
        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid
        submitHandler: function (form) {
            auLandingForm();
        }
    });
}

function auLandingForm() {
    try {

        // Get some values from elements on the page:
        const dataForm = $('#auLandingForm').serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        var formData = new FormData();
        let message = dataForm.touchMessage
        let currentLocation = window.location.href || ''
        message = message + " current page url: " + currentLocation
        formData.append("name", dataForm.touchName);
        formData.append("email", dataForm.touchEmail);
        formData.append("message", message);
        formData.append("phone", dataForm.phone);
        formData.append("countryCode", dataForm.countryCode);
        formData.append("formTitle", dataForm.formTitle);


        console.log('data touch form', formData)
        const url = "https://sven.dianapps.com/api/landing-form";
        // const url = "http://localhost:3000/api/landing-form";

        var settings = {
            "url": url,
            "method": "POST",
            "timeout": 0,
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": formData
        };
        $('#auLandingForm .form-processing').css('display', 'flex');
        $.ajax(settings).done(function (dataResp) {
            var hostname = window.location.origin
            $('#auLandingForm .form-processing').css('display', 'none');
            const result = JSON.parse(dataResp)
            $('#auLandingForm .form-error').hide();
            // $('#letsTalkForm .success').html(result.message);
            // $('#letsTalkForm .success').show();
            $('#auLandingForm').trigger("reset");

            let redirection = $('#auLandingForm').data('redirection');
            if (redirection == 'self') {
                if ($('#auLandingPageFormPopup').length) {
                    $('#auLandingPageFormPopup').modal('hide');
                } else if ($('#auLandingPageFormPopup_mob')) {
                    $('#auLandingPageFormPopup_mob').modal('hide');
                }
                thankyouPageRedirectOtherPages()

                // setTimeout(()=>{
                //     $('#landingpage_thankyou').modal('hide');
                // },5000);
                // $('#landingpage_thankyou').modal('show');
                return true;
            } else {
                thankyouPageRedirect()
            }

            thankyouPageRedirect()
        }).fail(function (errorResp) {
            $('#auLandingForm .form-processing').css('display', 'none');
            const error = JSON.parse(errorResp.responseText)
            $('#auLandingForm .success').hide();
            $('#auLandingForm .form-error').html(error.message)
            $('#auLandingForm .form-error').show();
        });

    } catch (err) {
        console.log('err', err)
    }
}
